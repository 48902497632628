import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.array.concat.js";
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
import { computed, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import ProductIcon from '@/components/ProductComponents/ProductIcon';
import { currentBP } from '@/composables/windowWatcher';
import { useCarouselController } from '@/composables/carouselController';
export default {
  props: {
    links: Object
  },
  setup: function setup(__props) {
    var props = __props;
    var route = useRoute();
    var suppressUpdate = false;

    var _useCarouselControlle = useCarouselController({}),
        setSlideNum = _useCarouselControlle.setSlideNum,
        activeSlide = _useCarouselControlle.activeSlide;

    var linksMobileCarousel = ref(null);
    var activeIndex = computed(function () {
      return Math.max(0, activeSlide.value - 1); // Adjust for product landing by offsetting by 1
    });

    var handleSlideChange = function handleSlideChange(index) {
      if (!suppressUpdate) {
        setSlideNum(index + 1, true); // Adjust for product landing by offsetting by 1
      } else {
        suppressUpdate = false;
      }
    };

    watch(activeSlide, function (newVal, oldVal) {
      if (newVal !== oldVal) {
        if (linksMobileCarousel.value) {
          suppressUpdate = newVal === 0 && oldVal > 0;
          linksMobileCarousel.value.setActiveItem(activeIndex.value);
        }
      }
    });
    return function (_ctx, _cache) {
      var _component_el_carousel_item = _resolveComponent("el-carousel-item");

      var _component_el_carousel = _resolveComponent("el-carousel");

      var _component_el_row = _resolveComponent("el-row");

      var _component_el_col = _resolveComponent("el-col");

      return _openBlock(), _createBlock(_component_el_row, null, {
        default: _withCtx(function () {
          return [_createVNode(_component_el_col, {
            class: "flex-col flex-centered"
          }, {
            default: _withCtx(function () {
              return [['xs', 'sm'].includes(_unref(currentBP)) ? (_openBlock(), _createBlock(_component_el_row, {
                key: 0,
                class: "fullwidth"
              }, {
                default: _withCtx(function () {
                  return [_createVNode(_component_el_carousel, {
                    ref_key: "linksMobileCarousel",
                    ref: linksMobileCarousel,
                    height: "120px",
                    "initial-index": _unref(activeIndex),
                    type: "card",
                    arrow: "always",
                    "indicator-position": "none",
                    autoplay: false,
                    class: "fullwidth",
                    onChange: handleSlideChange
                  }, {
                    default: _withCtx(function () {
                      return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.links, function (v, k) {
                        var _v$meta, _v$title;

                        return _openBlock(), _createElementBlock(_Fragment, {
                          key: k
                        }, [(_v$meta = v.meta) !== null && _v$meta !== void 0 && _v$meta.frontend_icon_text && (_v$title = v.title) !== null && _v$title !== void 0 && _v$title.rendered ? (_openBlock(), _createBlock(_component_el_carousel_item, {
                          key: 0,
                          name: k,
                          onClick: function onClick() {
                            return _unref(setSlideNum)(k, true);
                          }
                        }, {
                          default: _withCtx(function () {
                            return [_createVNode(_unref(ProductIcon), {
                              active: "",
                              icon_letter: v.meta.frontend_icon_text[0],
                              name: v.title.rendered.replace(' ', '\n')
                            }, null, 8, ["icon_letter", "name"])];
                          }),
                          _: 2
                        }, 1032, ["name", "onClick"])) : _createCommentVNode("", true)], 64);
                      }), 128))];
                    }),
                    _: 1
                  }, 8, ["initial-index"])];
                }),
                _: 1
              })) : (_openBlock(), _createBlock(_component_el_row, {
                key: 1,
                align: "top",
                justify: "space-evenly",
                class: "product-bar"
              }, {
                default: _withCtx(function () {
                  return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.links, function (v, k) {
                    var _v$meta2, _v$title2;

                    return _openBlock(), _createElementBlock(_Fragment, {
                      key: k
                    }, [(_v$meta2 = v.meta) !== null && _v$meta2 !== void 0 && _v$meta2.frontend_icon_text && (_v$title2 = v.title) !== null && _v$title2 !== void 0 && _v$title2.rendered ? (_openBlock(), _createBlock(_unref(ProductIcon), {
                      key: 0,
                      icon_letter: v.meta.frontend_icon_text[0],
                      name: v.title.rendered.replace(' ', '\n'),
                      router_link: {
                        path: "/products/".concat(_unref(route).meta.baseSlug, "/").concat(k),
                        replace: true
                      },
                      active: _unref(route).params.slug === k
                    }, null, 8, ["icon_letter", "name", "router_link", "active"])) : _createCommentVNode("", true)], 64);
                  }), 128))];
                }),
                _: 1
              }))];
            }),
            _: 1
          })];
        }),
        _: 1
      });
    };
  }
};