import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "size-page-section-header",
  style: {
    "text-align": "center"
  }
};
import { reactive, onMounted } from 'vue';
import { onBeforeRouteUpdate } from 'vue-router';
import { parseProductCards } from "@/composables/content.js";
import WaveSpinner from '@/components/WaveSpinner.vue';
import ProductOptionCard from '@/components/ProductComponents/ProductOptionCard.vue';
export default {
  props: {
    header: {
      type: String,
      required: false,
      default: 'How Will You Create Today?'
    },
    slugs: {
      type: Array,
      required: false
    }
  },
  setup: function setup(__props) {
    var props = __props;
    var state = reactive({
      loading: true,
      error: false,
      data: {}
    });
    onMounted(function () {
      parseProductCards(props.slugs, state, {}, true);
    });
    onBeforeRouteUpdate(function (to, from) {
      if (to.path !== from.path) {
        parseProductCards(props.slugs, state, {}, true);
      }
    });
    return function (_ctx, _cache) {
      var _component_el_row = _resolveComponent("el-row");

      var _component_el_col = _resolveComponent("el-col");

      return _unref(state).loading ? (_openBlock(), _createBlock(_component_el_row, {
        key: 0,
        align: "middle",
        justify: "center",
        style: {
          "height": "100%"
        }
      }, {
        default: _withCtx(function () {
          return [_createVNode(WaveSpinner)];
        }),
        _: 1
      })) : !_unref(state).error && _unref(state).data.cards.length ? (_openBlock(), _createBlock(_component_el_row, {
        key: 1,
        align: "middle",
        justify: "center"
      }, {
        default: _withCtx(function () {
          return [_createVNode(_component_el_col, {
            class: "std-section-padding"
          }, {
            default: _withCtx(function () {
              return [__props.header ? (_openBlock(), _createBlock(_component_el_row, {
                key: 0,
                align: "middle",
                justify: "center",
                class: "std-padding_line-xl"
              }, {
                default: _withCtx(function () {
                  return [_createElementVNode("h2", _hoisted_1, _toDisplayString(__props.header), 1)];
                }),
                _: 1
              })) : _createCommentVNode("", true), _createVNode(_component_el_row, {
                justify: "center",
                gutter: 50
              }, {
                default: _withCtx(function () {
                  return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(state).data.cards, function (card, index) {
                    return _openBlock(), _createBlock(_component_el_col, {
                      key: index,
                      md: 24 / _unref(state).data.cards.length,
                      class: "std-padding_line-xl flex-col",
                      style: {
                        "display": "flex !important",
                        "align-items": "center"
                      }
                    }, {
                      default: _withCtx(function () {
                        return [_createVNode(ProductOptionCard, {
                          productDetails: card
                        }, null, 8, ["productDetails"])];
                      }),
                      _: 2
                    }, 1032, ["md"]);
                  }), 128))];
                }),
                _: 1
              })];
            }),
            _: 1
          })];
        }),
        _: 1
      })) : _createCommentVNode("", true);
    };
  }
};