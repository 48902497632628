import { createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-41fce385"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "fill-container",
  style: {
    "max-width": "400px"
  }
};
var _hoisted_2 = {
  class: "featured_card fill-container"
};
import ImageWithAlt from '@/components/ImageWithAlt.vue';
export default {
  props: {
    featured_product: {
      type: Object,
      required: true
    }
  },
  setup: function setup(__props) {
    var props = __props;
    return function (_ctx, _cache) {
      var _component_el_row = _resolveComponent("el-row");

      var _component_router_link = _resolveComponent("router-link");

      return _openBlock(), _createBlock(_component_el_row, {
        class: "fill-container",
        justify: "center"
      }, {
        default: _withCtx(function () {
          return [_createElementVNode("div", _hoisted_1, [_createVNode(_component_router_link, {
            to: {
              name: 'Subscriptions',
              params: {
                slug: __props.featured_product.slug
              }
            },
            replace: ""
          }, {
            default: _withCtx(function () {
              return [_createElementVNode("div", _hoisted_2, [_createVNode(_component_el_row, {
                align: "middle",
                justify: "center",
                class: "std-padding_line-md"
              }, {
                default: _withCtx(function () {
                  return [_createVNode(ImageWithAlt, {
                    src: __props.featured_product.logo,
                    fit: "contain",
                    style: {
                      "max-width": "300px"
                    }
                  }, null, 8, ["src"])];
                }),
                _: 1
              }), _createVNode(_component_el_row, {
                align: "middle",
                justify: "center",
                style: {
                  "text-align": "center"
                }
              }, {
                default: _withCtx(function () {
                  return [_createElementVNode("p", null, _toDisplayString(__props.featured_product.tagline), 1)];
                }),
                _: 1
              })])];
            }),
            _: 1
          }, 8, ["to"])])];
        }),
        _: 1
      });
    };
  }
};