import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-24ff1f4c"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = ["innerHTML"];
var _hoisted_2 = ["innerHTML"];
import ProductKeyFeature from '@/components/ProductComponents/ProductKeyFeature.vue';
export default {
  props: {
    title: {
      type: String,
      default: "Key Features"
    },
    features: Object,
    disableCircles: Boolean
  },
  setup: function setup(__props) {
    var props = __props;
    return function (_ctx, _cache) {
      var _component_el_row = _resolveComponent("el-row");

      var _component_el_col = _resolveComponent("el-col");

      return _openBlock(), _createBlock(_component_el_row, null, {
        default: _withCtx(function () {
          return [_createVNode(_component_el_col, null, {
            default: _withCtx(function () {
              return [_createVNode(_component_el_row, {
                justify: "center",
                align: "middle",
                class: "std-padding_line-xl"
              }, {
                default: _withCtx(function () {
                  return [_createElementVNode("h2", {
                    class: "size-page-section-header",
                    style: {
                      "text-align": "center"
                    },
                    innerHTML: __props.title
                  }, null, 8, _hoisted_1)];
                }),
                _: 1
              }), _createVNode(_component_el_row, {
                justify: "space-evenly",
                align: "top",
                class: "features"
              }, {
                default: _withCtx(function () {
                  return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.features, function (v, i) {
                    return _openBlock(), _createBlock(ProductKeyFeature, {
                      key: i,
                      icon: v.icon,
                      disableCircle: __props.disableCircles,
                      class: "feature"
                    }, {
                      default: _withCtx(function () {
                        return [_createElementVNode("h3", {
                          innerHTML: v.text
                        }, null, 8, _hoisted_2)];
                      }),
                      _: 2
                    }, 1032, ["icon", "disableCircle"]);
                  }), 128))];
                }),
                _: 1
              })];
            }),
            _: 1
          })];
        }),
        _: 1
      });
    };
  }
};