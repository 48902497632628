import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withCtx as _withCtx, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-4adb04f9"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "key-feature"
};
export default {
  props: {
    icon: String,
    disableCircle: Boolean
  },
  setup: function setup(__props) {
    var props = __props;
    return function (_ctx, _cache) {
      var _component_el_image = _resolveComponent("el-image");

      var _component_el_row = _resolveComponent("el-row");

      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_row, {
        justify: "center",
        style: {
          "margin-bottom": "20px"
        }
      }, {
        default: _withCtx(function () {
          return [_createElementVNode("div", {
            class: _normalizeClass(!__props.disableCircle ? 'circle' : '')
          }, [_createVNode(_component_el_image, {
            src: __props.icon,
            class: "icon"
          }, null, 8, ["src"])], 2)];
        }),
        _: 1
      }), _createVNode(_component_el_row, {
        justify: "center"
      }, {
        default: _withCtx(function () {
          return [_renderSlot(_ctx.$slots, "default")];
        }),
        _: 3
      })]);
    };
  }
};