import "core-js/modules/es.function.name.js";
import { createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-b41c00c4"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = ["innerHTML"];
var _hoisted_2 = ["innerHTML"];
var _hoisted_3 = ["innerHTML"];
import CarouselSlide from '@/components/CarouselBanner/CarouselSlide.vue';
import ProductSlideContent from '@/components/ProductComponents/ProductSlideContent.vue';
export default {
  props: {
    slide: {
      type: Object,
      required: true
    }
  },
  setup: function setup(__props) {
    var props = __props;
    return function (_ctx, _cache) {
      return _openBlock(), _createBlock(CarouselSlide, {
        name: __props.slide.name
      }, {
        default: _withCtx(function () {
          return [_createVNode(ProductSlideContent, {
            class: "content",
            "data-testid": "".concat(__props.slide.name, "-slide"),
            img_src: __props.slide.gui,
            centered: !__props.slide.gui,
            buy_url: __props.slide.buy_path,
            buy_path: __props.slide.name,
            m_buy_path: __props.slide.m_buy_path,
            aux_btns: __props.slide.aux_btns,
            cta_link: __props.slide.cta_link,
            featured_products: __props.slide.featured_products,
            exclude_trial: __props.slide.hide_default_trial_cta
          }, {
            name: _withCtx(function () {
              return [_createElementVNode("span", {
                innerHTML: __props.slide.header
              }, null, 8, _hoisted_1)];
            }),
            tagline: _withCtx(function () {
              return [_createElementVNode("span", {
                innerHTML: __props.slide.tagline
              }, null, 8, _hoisted_2)];
            }),
            summary: _withCtx(function () {
              return [_createElementVNode("span", {
                innerHTML: __props.slide.summary
              }, null, 8, _hoisted_3)];
            }),
            _: 1
          }, 8, ["data-testid", "img_src", "centered", "buy_url", "buy_path", "m_buy_path", "aux_btns", "cta_link", "featured_products", "exclude_trial"])];
        }),
        _: 1
      }, 8, ["name"]);
    };
  }
};