import _typeof from "/var/www/git/antares-web-frontend/node_modules/@babel/runtime/helpers/esm/typeof.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.object.values.js";
import "core-js/modules/es.array.find-index.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.object.keys.js";
import { useCssVars as _useCssVars } from 'vue';
import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createSlots as _createSlots } from "vue";
import VideoBG from "@/components/VideoBG.vue";
import { ref, reactive, watch, onMounted, computed } from 'vue';
import { useRoute, useRouter } from "vue-router";
import WaveSpinner from "@/components/WaveSpinner";
import CarouselContainer from "@/components/CarouselBanner/CarouselContainer.vue";
import ProductSlide from "@/components/ProductComponents/ProductSlide";
import ProductLinks from "./ProductLinks.vue";
import ProductResources from './ProductResources.vue';
export default {
  props: {
    loading: Boolean,
    slides: Object,
    links: Object,
    hideLinks: Boolean,
    initialSlide: {
      type: Number,
      required: false,
      default: 0
    },
    productLineBgImg: {
      type: String,
      required: true
    },
    productLineBgVid: {
      type: String,
      required: false,
      default: ''
    }
  },
  setup: function setup(__props) {
    var props = __props;

    _useCssVars(function (_ctx) {
      return {
        "7296dc52": _ctx.gradient
      };
    });

    var carousel = ref(null);
    var route = useRoute();
    var router = useRouter();
    var state = reactive({
      BGs: [null, null]
    });
    var activeOverride = ref(false);

    var handleSlideChange = function handleSlideChange(key) {
      var slug = "";

      if (typeof key === "string") {
        slug = key;
      } else if (_typeof(key) === "object" && key !== null && key !== void 0 && key.name) {
        slug = key.name;
      }

      router.replace({
        path: "/products/".concat(route.meta.baseSlug, "/").concat(slug)
      });
    };
    /**
     * 
     * @param {String} key 
     */


    var updateCarousels = function updateCarousels(key) {
      var _carousel$value;

      (_carousel$value = carousel.value) === null || _carousel$value === void 0 ? void 0 : _carousel$value.setSlideNum(key === "" ? 0 : key);
    };

    var slideNum = computed(function () {
      if (!route.params.slug || !Object.values(props.slides).length) {
        return 0;
      }

      var index = Object.values(props.slides).findIndex(function (elem) {
        return elem.name === route.params.slug;
      });
      return index === -1 ? 0 : index;
    });
    var BG = computed(function () {
      var slides = Object.values(props.slides);
      var bg = {
        image: props.productLineBgImg,
        video: props.productLineBgVid,
        mix_mode: 'color-dodge'
      };

      if (slides.length && carousel.value) {
        var _slides$carousel$valu, _slides$carousel$valu2;

        if ((_slides$carousel$valu = slides[carousel.value.activeSlide].background) !== null && _slides$carousel$valu !== void 0 && _slides$carousel$valu.image || (_slides$carousel$valu2 = slides[carousel.value.activeSlide].background) !== null && _slides$carousel$valu2 !== void 0 && _slides$carousel$valu2.video) {
          bg = {
            image: slides[carousel.value.activeSlide].background.image,
            video: slides[carousel.value.activeSlide].background.video,
            mix_mode: slides[carousel.value.activeSlide].background.blendMode || 'normal'
          };
        }
      }

      return bg;
    });
    onMounted(function () {
      state.BGs[0] = BG.value;
    });
    watch(BG, function (newVal, oldVal) {
      if (Object.keys(oldVal).some(function (key) {
        return oldVal[key] !== newVal[key];
      })) {
        activeOverride.value = !activeOverride.value;
        state.BGs[activeOverride.value ? 1 : 0] = newVal;
      }
    });
    router.beforeEach(function (to, from) {
      var _to$params, _from$params;

      if (to.name === from.name && ((_to$params = to.params) === null || _to$params === void 0 ? void 0 : _to$params.slug) !== ((_from$params = from.params) === null || _from$params === void 0 ? void 0 : _from$params.slug)) {
        var _to$params2;

        updateCarousels(((_to$params2 = to.params) === null || _to$params2 === void 0 ? void 0 : _to$params2.slug) || "");
      }
    });
    return function (_ctx, _cache) {
      var _component_el_col = _resolveComponent("el-col");

      var _component_el_row = _resolveComponent("el-row");

      var _component_el_container = _resolveComponent("el-container");

      return _openBlock(), _createBlock(_component_el_row, {
        style: {
          "height": "100%",
          "overflow-x": "clip"
        }
      }, {
        default: _withCtx(function () {
          return [__props.loading ? (_openBlock(), _createBlock(_component_el_col, {
            key: 0
          }, {
            default: _withCtx(function () {
              return [_createVNode(_unref(WaveSpinner))];
            }),
            _: 1
          })) : (_openBlock(), _createBlock(_component_el_col, {
            key: 1,
            class: "flex-col",
            style: {
              "max-height": "100%",
              "position": "relative"
            }
          }, {
            default: _withCtx(function () {
              return [_createVNode(_Transition, {
                name: "fade"
              }, {
                default: _withCtx(function () {
                  return [!activeOverride.value && _unref(state).BGs[0] ? (_openBlock(), _createBlock(VideoBG, {
                    key: 0,
                    class: "overlayed-container",
                    style: {
                      "position": "absolute!important"
                    },
                    href: _unref(state).BGs[0].image,
                    src: _unref(state).BGs[0].video,
                    "video-use-overlay": "",
                    useParallax: "",
                    parallaxRate: 0.5,
                    "blend-mode": _unref(state).BGs[0].mix_mode
                  }, null, 8, ["href", "src", "parallaxRate", "blend-mode"])) : activeOverride.value && _unref(state).BGs[1] ? (_openBlock(), _createBlock(VideoBG, {
                    key: 1,
                    class: "overlayed-container",
                    style: {
                      "position": "absolute!important"
                    },
                    href: _unref(state).BGs[1].image,
                    src: _unref(state).BGs[1].video,
                    "video-use-overlay": "",
                    useParallax: "",
                    parallaxRate: 0.5,
                    "blend-mode": _unref(state).BGs[1].mix_mode
                  }, null, 8, ["href", "src", "parallaxRate", "blend-mode"])) : _createCommentVNode("", true)];
                }),
                _: 1
              }), _createVNode(_component_el_container, {
                direction: "vertical",
                class: "fill-container"
              }, {
                default: _withCtx(function () {
                  return [_createVNode(_component_el_row, {
                    justify: "end"
                  }, {
                    default: _withCtx(function () {
                      return [_unref(route).params.slug && _unref(route).params.slug !== '' && _unref(route).params.slug !== 'auto-key-mobile' ? (_openBlock(), _createBlock(ProductResources, {
                        key: 0,
                        class: "product-resources-dropdown hidden-sm-and-down"
                      })) : _createCommentVNode("", true)];
                    }),
                    _: 1
                  }), _createVNode(CarouselContainer, {
                    style: {
                      "flex": "1 0 max-content"
                    },
                    autoplay: false,
                    pauseOnHover: false,
                    "initial-index": _unref(slideNum),
                    swipeSkipSlides: [''],
                    ref_key: "carousel",
                    ref: carousel,
                    onSlideChanged: handleSlideChange
                  }, _createSlots({
                    default: _withCtx(function () {
                      return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.slides, function (slide, index) {
                        return _openBlock(), _createBlock(_unref(ProductSlide), {
                          key: index,
                          slide: slide
                        }, null, 8, ["slide"]);
                      }), 128))];
                    }),
                    _: 2
                  }, [!__props.hideLinks ? {
                    name: "carousel-indicators",
                    fn: _withCtx(function () {
                      return [Object.values(__props.links).length > 1 ? (_openBlock(), _createBlock(ProductLinks, {
                        key: 0,
                        class: "fullwidth product-links",
                        style: {
                          "position": "absolute",
                          "bottom": "0"
                        },
                        links: __props.links
                      }, null, 8, ["links"])) : _createCommentVNode("", true)];
                    })
                  } : undefined]), 1032, ["initial-index"])];
                }),
                _: 1
              })];
            }),
            _: 1
          }))];
        }),
        _: 1
      });
    };
  }
};