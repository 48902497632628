import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeStyle as _normalizeStyle, unref as _unref, createCommentVNode as _createCommentVNode, Transition as _Transition, normalizeClass as _normalizeClass, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-10c057e4"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "size-page-section-header std-padding_line-sm"
};
var _hoisted_2 = {
  class: "desc flex-col hidden-md-and-up feature-container-m"
};
var _hoisted_3 = {
  style: {
    "position": "relative"
  }
};
var _hoisted_4 = {
  key: 0,
  class: "overlayed-container flex-col flex-centered",
  style: {
    "border-radius": "50%"
  }
};
var _hoisted_5 = {
  class: "flex-col flex-centered"
};
var _hoisted_6 = {
  class: "size-page-section-header std-padding_line-sm"
};
var _hoisted_7 = {
  class: "hidden-sm-and-down",
  style: {
    "position": "relative"
  }
};
var _hoisted_8 = {
  key: 0,
  class: "overlayed-container flex-col flex-centered",
  style: {
    "border-radius": "50%"
  }
};
import CircleImageParallax from '@/components/CircleImageParallax';
import { inject } from 'vue';
import { Play } from '@/assets/SVGs/AutoTuneIconLib';
export default {
  props: {
    data: {
      type: Object,
      required: true
    },
    ringColor: String
  },
  setup: function setup(__props) {
    var videoModal = inject('videoModal');
    /**
     * Sets the featured row's height
     * @param {number} i
     */

    var setRowHeight = function setRowHeight(data) {
      return "".concat(400 + parseInt(data.img_size_type) * 100, "px");
    };

    var handleVideo = function handleVideo(_ref) {
      var title = _ref.title,
          url = _ref.url;

      if (videoModal.value && typeof videoModal.value.openVideoModal === 'function') {
        videoModal.value.openVideoModal({
          title: title,
          url: url
        });
      }
    };

    return function (_ctx, _cache) {
      var _component_el_col = _resolveComponent("el-col");

      var _component_el_row = _resolveComponent("el-row");

      var _component_el_button = _resolveComponent("el-button");

      return _openBlock(), _createBlock(_component_el_col, {
        class: "feature-rows flex-col flex-centered"
      }, {
        default: _withCtx(function () {
          return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.data, function (v, i) {
            return _openBlock(), _createBlock(_component_el_row, {
              key: i,
              align: "middle",
              class: _normalizeClass({
                featureRow: true,
                offsetHeight: i > 0
              }),
              style: _normalizeStyle({
                height: setRowHeight(v)
              })
            }, {
              default: _withCtx(function () {
                return [_createElementVNode("div", {
                  class: "desc flex-col hidden-sm-and-down",
                  style: _normalizeStyle({
                    width: "calc(100% - ".concat(setRowHeight(v), ")")
                  })
                }, [_createVNode(_component_el_row, {
                  class: "std-padding_section-vertical"
                }, {
                  default: _withCtx(function () {
                    return [_createVNode(_component_el_col, {
                      class: "flex-col desc-col"
                    }, {
                      default: _withCtx(function () {
                        return [_createElementVNode("h3", _hoisted_1, _toDisplayString(v.title), 1), _createTextVNode(" " + _toDisplayString(v.desc), 1)];
                      }),
                      _: 2
                    }, 1024)];
                  }),
                  _: 2
                }, 1024)], 4), _createElementVNode("div", _hoisted_2, [_createVNode(_component_el_row, {
                  align: "middle",
                  justify: "center",
                  class: "std-padding_line-lg"
                }, {
                  default: _withCtx(function () {
                    return [_createElementVNode("div", _hoisted_3, [_createVNode(_unref(CircleImageParallax), {
                      src: v.img_src,
                      color: __props.ringColor || null,
                      orientation: i % 2 === 0 ? 'right' : 'left',
                      style: {
                        width: "70vw"
                      }
                    }, null, 8, ["src", "color", "orientation"]), _createVNode(_Transition, {
                      name: "fast-fade-centered"
                    }, {
                      default: _withCtx(function () {
                        return [v.video ? (_openBlock(), _createElementBlock("div", _hoisted_4, [_createVNode(_component_el_button, {
                          size: "large",
                          type: "primary",
                          class: "extra-large black-base btn-override",
                          circle: "",
                          icon: _unref(Play),
                          onClick: function onClick($event) {
                            return handleVideo({
                              title: '',
                              url: v.video
                            });
                          }
                        }, null, 8, ["icon", "onClick"])])) : _createCommentVNode("", true)];
                      }),
                      _: 2
                    }, 1024)])];
                  }),
                  _: 2
                }, 1024), _createVNode(_component_el_row, {
                  align: "middle",
                  justify: "center"
                }, {
                  default: _withCtx(function () {
                    return [_createElementVNode("div", _hoisted_5, [_createElementVNode("h3", _hoisted_6, _toDisplayString(v.title), 1), _createElementVNode("p", null, _toDisplayString(v.desc), 1)])];
                  }),
                  _: 2
                }, 1024)]), _createElementVNode("div", _hoisted_7, [_createVNode(_unref(CircleImageParallax), {
                  src: v.img_src,
                  color: __props.ringColor || null,
                  orientation: i % 2 === 0 ? 'right' : 'left',
                  style: _normalizeStyle({
                    width: setRowHeight(v)
                  })
                }, null, 8, ["src", "color", "orientation", "style"]), _createVNode(_Transition, {
                  name: "fast-fade-centered"
                }, {
                  default: _withCtx(function () {
                    return [v.video ? (_openBlock(), _createElementBlock("div", _hoisted_8, [_createVNode(_component_el_button, {
                      size: "large",
                      type: "primary",
                      class: "extra-large black-base btn-override",
                      circle: "",
                      icon: _unref(Play),
                      onClick: function onClick($event) {
                        return handleVideo({
                          title: '',
                          url: v.video
                        });
                      }
                    }, null, 8, ["icon", "onClick"])])) : _createCommentVNode("", true)];
                  }),
                  _: 2
                }, 1024)])];
              }),
              _: 2
            }, 1032, ["class", "style"]);
          }), 128))];
        }),
        _: 1
      });
    };
  }
};